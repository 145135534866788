'use client';

import { useTranslations } from 'next-intl';
import Link from 'next/link';
import { useParams } from 'next/navigation';

import type { CommunityMember } from '@zealy/queries';
import type { BlockchainNetwork } from '@zealy/utils';
import { TaskCard, TaskCardHint } from '@zealy/design-system';
import { QuestionFilled } from '@zealy/icons';
import { useAuthenticatedUser, useCommunity } from '@zealy/queries';
import { roleIsAtLeast } from '@zealy/utils';

import { TASK_ICONS } from '#constants/quests/TaskIcons';
import { ConnectWallet as ConnectButton } from '#features/Wallets/ConnectWallet';

const ConnectWallet = ({ blockchain = 'eth-mainnet' }: { blockchain: BlockchainNetwork }) => {
  const { subdomain } = useParams<{ subdomain: string }>();
  const { data: community } = useCommunity(subdomain);
  const user = useAuthenticatedUser<CommunityMember>();
  const tChain = useTranslations('quest.type.nft.fields.network.options');
  const t = useTranslations('settings.linked-accounts');
  const title = `${t('connect')} ${tChain(blockchain)} ${t('wallet.title')}`;

  const isAdmin = roleIsAtLeast(user?.data?.role, 'admin');
  const shouldShowHint = isAdmin && community?.requiredFields?.linkWallet;

  return (
    <TaskCard
      title={title}
      type="wallet"
      className="px-200 py-300"
      icon={TASK_ICONS.wallet}
      hint={
        shouldShowHint && (
          <TaskCardHint
            icon={<QuestionFilled />}
            tooltip={t.rich('wallet.hint', {
              link: chunks => (
                <Link href={`/cw/${subdomain}/settings/linked-accounts`} className="underline">
                  {chunks}
                </Link>
              ),
            })}
          >
            {t('wallet.hint-trigger')}
          </TaskCardHint>
        )
      }
    >
      <ConnectButton
        blockchain={blockchain}
        buttonProps={{
          color: 'default',
          className: 'w-full muted button-lg',
          size: 'lg',
        }}
      />
    </TaskCard>
  );
};

export default ConnectWallet;
