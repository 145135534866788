import React, { useRef } from 'react';
import { useTranslations } from 'next-intl';

import type { QuestData } from '#components/QuestCard';
import type { RewardCarouselRef } from '#components/RewardCarousel';
import { RewardCard } from '#components/RewardCard';
import { RewardCarousel } from '#components/RewardCarousel';
import { flattenRewardMethod } from '#utils/flattenReward';

export interface RewardsProps {
  quest: QuestData;
}

export const Rewards = ({ quest }: RewardsProps) => {
  const t = useTranslations('common');
  const carouselRef = useRef<RewardCarouselRef>(null);

  return (
    <div className="min-w-0">
      {!!quest.rewards?.length && (
        <div className="flex flex-col gap-100">
          <span className="body-paragraph-lg-bold text-primary">{t('rewards')}</span>
          <div className="flex gap-200 overflow-x-auto no-scrollbar">
            {quest.rewards.map(({ method, ...reward }, index) => (
              <RewardCard
                onClick={() => carouselRef.current?.show(index)}
                size="md"
                key={index.toString()}
                {...{ ...reward, ...flattenRewardMethod(method) }}
                quest={quest}
              />
            ))}
          </div>
        </div>
      )}
      <RewardCarousel ref={carouselRef} rewards={quest.rewards} quest={quest} />
    </div>
  );
};
