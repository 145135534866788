import { useRef, useState } from 'react';
import { useTranslations } from 'next-intl';
import dynamic from 'next/dynamic';
import Link from 'next/link';

import type { GetQuestOutput } from '@zealy/utils';
import { Button } from '@zealy/design-system';

import { RewardCard } from '#components/RewardCard';
import { useCommunityURL } from '#hooks/useCommunityURL';
import { useNextQuest } from '#hooks/useNextQuest';
import { flattenRewardMethod } from '#utils/flattenReward';

import { ClaimReward } from './ClaimReward';
import { Base } from './StateBase';

type SuccessProps = {
  questId: string;
  moduleId: string;
  rewards: GetQuestOutput['rewards'];
  quest: GetQuestOutput;
};

export const Success = ({ rewards, moduleId, quest, questId }: SuccessProps) => {
  const t = useTranslations();
  const divRef = useRef<HTMLDivElement>(null);

  const [shouldShownClaimAnimation, setShouldShownClaimAnimation] = useState(false);
  const nextQuest = useNextQuest(moduleId, questId);
  const baseURL = useCommunityURL();
  const nextQuestURL = `${baseURL}/questboard/${nextQuest?.categoryId}/${nextQuest?.id}`;

  const Confetti = dynamic(() => import('react-confetti'), {
    ssr: false,
  });

  const voteRewards = rewards.filter(reward => reward.method?.type === 'vote');
  const onlyVoteRewards = rewards.length === voteRewards.length;

  const isClaimed = shouldShownClaimAnimation;

  const onClaim = () => {
    setShouldShownClaimAnimation(true);
  };

  if (isClaimed) {
    return <ClaimReward questId={questId} moduleId={moduleId} rewards={rewards} quest={quest} />;
  }

  const getDescription = () => {
    if (voteRewards.length > 0 && onlyVoteRewards) {
      return 'vote-rewards';
    }
    if (voteRewards.length > 0 && !onlyVoteRewards) {
      return 'claim-vote-rewards';
    }
    return 'claim-rewards';
  };

  return (
    <Base
      title={t('communities.claim.completed.title')}
      description={`${t('communities.claim.completed.description.default')} ${t(
        `communities.claim.completed.description.${getDescription()}`,
        {
          count: rewards.length,
        },
      )}`}
      mascot="happy-jump"
      ref={divRef}
    >
      <div className="flex flex-col gap-200">
        <div className="flex flex-row gap-200 justify-center">
          {rewards.map((reward, index) => (
            <RewardCard
              size="sm"
              key={index.toString()}
              {...{ ...reward, ...flattenRewardMethod(reward.method) }}
              quest={quest}
            />
          ))}
        </div>

        {rewards.length > 0 && !onlyVoteRewards && (
          <Button onClick={onClaim} color="cta" className="mt-300" size="lg">
            {t('common.claim-rewards', { count: rewards.length })}
          </Button>
        )}
        {voteRewards.length > 0 && (
          <Button
            color="cta"
            as={Link}
            href={`${baseURL}/questboard/${moduleId}/${questId}/submissions`}
          >
            {t('communities.claim.completed.vote-cta')}
          </Button>
        )}
        {rewards.length === 0 &&
          (nextQuest ? (
            <Button href={nextQuestURL} color="cta" as={Link} className="mt-300" size="lg">
              {t('common.next-quest')}
            </Button>
          ) : (
            <Button
              href={`${baseURL}/questboard`}
              color="cta"
              as={Link}
              className="mt-300"
              size="lg"
            >
              {t('quest.back')}
            </Button>
          ))}

        <Confetti
          numberOfPieces={300}
          recycle={false}
          width={divRef.current?.offsetWidth}
          height={divRef.current?.offsetHeight}
        />
      </div>
    </Base>
  );
};
