import { useTranslations } from 'next-intl';
import Link from 'next/link';
import { useParams } from 'next/navigation';

import type { CommunityMember } from '@zealy/queries';
import { Button, TaskCard, TaskCardHint } from '@zealy/design-system';
import { QuestionFilled, TwitterFilled } from '@zealy/icons';
import { useAuthenticatedUser, useCommunity } from '@zealy/queries';
import { roleIsAtLeast } from '@zealy/utils';

import { TASK_CONFIG } from '#constants/quests/Task.constants';
import { useOauth2 } from '#hooks/useOAuth2';

const ConnectTwitter = () => {
  const twitterAuth = useOauth2('twitter');
  const { subdomain } = useParams<{ subdomain: string }>();
  const { data: community } = useCommunity(subdomain);
  const user = useAuthenticatedUser<CommunityMember>();
  const t = useTranslations('settings.linked-accounts');

  const title = `${t('connect')} ${t('twitter.title')}`;

  const isAdmin = roleIsAtLeast(user?.data?.role, 'admin');
  const shouldShowHint = isAdmin && community?.requiredFields?.linkTwitter;

  return (
    <TaskCard
      title={title}
      type="twitter"
      className="px-200 py-300"
      icon={TASK_CONFIG.twitterFollow.icon}
      hint={
        shouldShowHint && (
          <TaskCardHint
            icon={<QuestionFilled />}
            tooltip={t.rich('twitter.hint', {
              link: chunks => (
                <Link href={`/cw/${subdomain}/settings/linked-accounts`} className="underline">
                  {chunks}
                </Link>
              ),
            })}
          >
            {t('twitter.hint-trigger')}
          </TaskCardHint>
        )
      }
    >
      <Button
        leftIcon={<TwitterFilled />}
        size="lg"
        className="w-full text-white bg-task-type-twitter hover:bg-task-type-twitter-hover active:bg-task-type-twitter-press"
        onClick={() => twitterAuth.open()}
      >
        {title}
      </Button>
    </TaskCard>
  );
};

export default ConnectTwitter;
