// types.ts

import type { GetQuestOutput } from '@zealy/utils';

export type RewardType = GetQuestOutput['rewards'][number];

export type PartialQuest = Pick<
  GetQuestOutput,
  'conditions' | 'claimLimit' | 'claimCount' | 'completed' | 'categoryId' | 'id'
>;

export enum REWARD_STATUS {
  PENDING_END_DATE = 'pending-end-date',
  PENDING_CLAIM_LIMIT = 'pending-claim-limit',
  REWARDED_MANUAL = 'waiting-admin',
  REWARDED_AUTOMATIC = 'rewarded',
  NOT_REWARDED = 'not-rewarded',
}

export interface RewardPropertyProps {
  reward: RewardType;
  orientation?: 'horizontal' | 'vertical';
  quest: PartialQuest;
  className?: string;
}

export type RewardPropertyComponent = React.ComponentType<RewardPropertyProps>;
