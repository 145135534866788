import { useState } from 'react';
import { useTranslations } from 'next-intl';

import type { FormControlProps } from '@zealy/design-system';
import type { Community } from '@zealy/utils';
import { Button, FormControl, Input, TaskCard } from '@zealy/design-system';
import { WalletFilled } from '@zealy/icons';
import { useUpdateAuthenticatedUser } from '@zealy/queries';
import { capitalize, validateAddress } from '@zealy/utils';

import { toast } from '#components/Toaster';

export const InputBlockchainAddress = ({
  blockchain,
  size = 'md',
  className,
}: {
  blockchain: Community['blockchain'];
  size?: FormControlProps['size'];
  className?: string;
}) => {
  const [address, setAddress] = useState('');
  const updateUser = useUpdateAuthenticatedUser();
  const t = useTranslations('settings');
  const tCommon = useTranslations('common');

  const invalidAddress = !!address && !validateAddress(blockchain, address);

  const onSave = () => {
    if (!invalidAddress) {
      updateUser.mutate(
        {
          blockchain,
          address,
        },
        {
          onError: () => toast.error(tCommon('toast-error')),
        },
      );
    }
  };

  return (
    <FormControl
      name={blockchain}
      state={invalidAddress ? 'error' : undefined}
      hint={
        invalidAddress ? t('profile.address.invalid', { blockchain: capitalize(blockchain) }) : ''
      }
      size={size}
      label={undefined}
    >
      <div className="flex gap-100">
        <Input
          name={blockchain}
          placeholder={t('profile.address.placeholder', {
            blockchain: capitalize(blockchain),
          })}
          size={size}
          isInvalid={invalidAddress}
          value={address}
          onChange={e => setAddress(e.target.value)}
          className={className}
        />
        <Button
          size={size}
          onClick={onSave}
          isDisabled={invalidAddress}
          loading={updateUser.isPending}
          variant="muted"
        >
          {t('linked-accounts.save')}
        </Button>
      </div>
    </FormControl>
  );
};

const InputBlockchainAddressTask = ({ blockchain }: { blockchain: Community['blockchain'] }) => {
  const t = useTranslations('settings');

  const title = `${t('linked-accounts.link')} ${t(
    'linked-accounts.communityBlockchainAddress.title',
    {
      blockchain: capitalize(blockchain),
    },
  )}`;

  return (
    <TaskCard
      title={title}
      type="wallet"
      className="px-200 py-300 flex gap-100 items-end"
      icon={WalletFilled}
    >
      <InputBlockchainAddress blockchain={blockchain} size="xl" />
    </TaskCard>
  );
};

export default InputBlockchainAddressTask;
